if(document.querySelector('.tab')){
	let tabMenu = document.querySelectorAll('.tab-menu__item'),
		tabMenuItem = document.querySelectorAll('.tab-container__item');

		tabMenu.forEach( function(el, index) {
			
			el.addEventListener('click', function(e){
				tabMenu.forEach(function(el, i){
					tabMenuItem[i].classList.remove('active');
					el.setAttribute('aria-selected', false);
				});
				tabMenuItem[index].classList.add('active');
				el.setAttribute('aria-selected', true);
			});

		});
}