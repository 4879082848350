// navigation mob
// menu
if(document.querySelector('.hamburger')){
	let btnMenu = document.querySelector('.hamburger'),
		menuMob = document.querySelector('.navigation-mobile'),
		mobNavA = menuMob.querySelectorAll('.navigation-mobile a'),
		menuMobClose = menuMob.querySelector('.navigation-close'),
		body = document.body;

	btnMenu.addEventListener('click', function(){
		if(menuMob.classList.contains('open') === true){
			menuMob.classList.remove('open');
			body.classList.remove('body_scroll');
			this.setAttribute('aria-expanded', false);
		} else {
			menuMob.classList.add('open');
			body.classList.add('body_scroll');
			this.setAttribute('aria-expanded', true);
		}
	})

	menuMobClose.addEventListener('click', function(){
			menuMob.classList.remove('open');
			body.classList.remove('body_scroll');
			btnMenu.setAttribute('aria-expanded', false);
		});

	mobNavA.forEach(function(el){
		el.addEventListener('click', function(){
			menuMob.classList.remove('open');
			body.classList.remove('body_scroll');
			btnMenu.setAttribute('aria-expanded', false);
		});
	});

}